import * as React from 'react'
import Layout from '../layout'
import GamesPage from '../pagesComponents/games'

const Games = () => {
	return (
		<Layout title="Games">
			<GamesPage />
		</Layout>
	)
}

export default Games

import * as React from 'react'
import BasePage from '../components/basePage'
import GameItem from '../components/gameItem'
import { graphql, useStaticQuery } from 'gatsby'

const GamesPage = () => {
	const data = useStaticQuery(FetchAllQuery)
	const nodes = data?.allMarkdownRemark?.edges || []

	console.log('nodes', nodes)

	return (
		<BasePage
			theme="dark"
			backBtn="/"
			showMenuBtn={true}
			largeTitle="Games"
		>
			{nodes.map((item, index) => (
				<GameItem key={index} game={item.node?.frontmatter || {}} />
			))}
		</BasePage>
	)
}

export default GamesPage

const FetchAllQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: ASC, fields: frontmatter___id }
			filter: {
				frontmatter: { title: { ne: "" } }
				fields: { name: { ne: "game-0" } }
			}
		) {
			edges {
				node {
					frontmatter {
						id
						min
						max
						duration
						rounds
						desc
						banner {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	}
`
